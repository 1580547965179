import React from "react"
import {
  chakra, useColorModeValue
} from "@chakra-ui/react"


export const FacebookIcon = (props) => {
  return (
    <chakra.svg
      {...props}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.417 9.34785C19.418 4.40457 15.5819 0.325807 10.688 0.0666867C5.79408 -0.192433 1.55574 3.45881 1.04373 8.37504C0.531726 13.2913 3.92531 17.7512 8.76579 18.5236V12.0332H6.42714V9.34785H8.76579V7.30015C8.66385 6.33241 8.99349 5.36902 9.66551 4.67075C10.3375 3.97247 11.2824 3.61152 12.2444 3.68559C12.9353 3.69475 13.6246 3.75531 14.3067 3.86678V6.15608H13.146C12.7455 6.10325 12.3429 6.23688 12.0518 6.51926C11.7606 6.80165 11.6125 7.2022 11.6491 7.60794V9.35018H14.2042L13.7954 12.0355H11.6445V18.5294C16.1221 17.8149 19.4188 13.9206 19.417 9.34785Z"
        fill="#C1AAFD"
      />
    </chakra.svg>
  )
}

export const YoutubeIcon = (props) => {
  return (
    <chakra.svg
      {...props}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9873 3.51877C18.757 2.00884 18.0661 0.963496 16.454 0.731198C13.9208 0.266602 10.0058 0.266602 10.0058 0.266602C10.0058 0.266602 6.09074 0.266602 3.55749 0.731198C1.94542 0.963496 1.13939 2.00884 1.02424 3.51877C0.793945 5.02871 0.793945 7.23554 0.793945 7.23554C0.793945 7.23554 0.793945 9.44237 1.02424 10.9523C1.25454 12.4622 1.94542 13.5076 3.55749 13.7399C6.09074 14.2045 10.0058 14.2045 10.0058 14.2045C10.0058 14.2045 13.9208 14.2045 16.454 13.7399C18.0661 13.3914 18.757 12.4622 18.9873 10.9523C19.2176 9.44237 19.2176 7.23554 19.2176 7.23554C19.2176 7.23554 19.2176 5.02871 18.9873 3.51877ZM7.70288 10.7199V3.75097L13.4603 7.23544L7.70288 10.7199Z"
        fill="#C1AAFD"
      />
    </chakra.svg>
  )
}

export const InstagramIcon = (props) => {
  return (
    <chakra.svg
      {...props}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="14.5791" cy="4.5203" rx="1.10542" ry="1.11503" fill="#C1AAFD" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.8061 13.9374C7.26595 13.9374 5.2002 11.8537 5.2002 9.29147C5.2002 6.72922 7.26595 4.64551 9.8061 4.64551C12.3463 4.64551 14.412 6.72922 14.412 9.29147C14.412 11.8537 12.3463 13.9374 9.8061 13.9374ZM9.80628 6.9687C8.5362 6.9687 7.50332 8.01055 7.50332 9.29168C7.50332 10.5728 8.5362 11.6147 9.80628 11.6147C11.0764 11.6147 12.1092 10.5728 12.1092 9.29168C12.1092 8.01055 11.0764 6.9687 9.80628 6.9687Z"
            fill="#C1AAFD" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.412 18.5838H5.20015C2.83271 18.5838 0.594238 16.3259 0.594238 13.9379V4.64596C0.594238 2.25794 2.83271 0 5.20015 0H14.412C16.7794 0 19.0179 2.25794 19.0179 4.64596V13.9379C19.0179 16.3259 16.7794 18.5838 14.412 18.5838ZM5.20053 2.32307C4.1239 2.32307 2.89758 3.56006 2.89758 4.64605V13.938C2.89758 15.0449 4.10317 16.261 5.20053 16.261H14.4124C15.489 16.261 16.7153 15.024 16.7153 13.938V4.64605C16.7153 3.56006 15.489 2.32307 14.4124 2.32307H5.20053Z"
            fill="#C1AAFD" />
    </chakra.svg>
  )
}

export const YCombinatorIcon = (props) => {
  return (
    <chakra.svg
      {...props}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_280_4991)">
        <path
          d="M30.1812 0.392578H0.57373V29.9973H30.1812V0.392578Z"
          fill="#D45F2B"
        />
        <path d="M14.403 23.4012V16.8393L9.51172 7.93848H11.7856L13.9668 12.2079C14.5608 13.3773 15.0156 14.3148 15.4982 15.3914H15.5446C15.9808 14.389 16.5099 13.3773 17.1039 12.2079L19.3314 7.93848H21.6053L16.417 16.8207V23.4012H14.403Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_280_4991">
          <rect width="29.6075" height="29.6075" fill="white" transform="translate(0.57373 0.392578)"/>
        </clipPath>
      </defs>
    </chakra.svg>

  )
}

export const Logo = (props) => {
  const fillColor = useColorModeValue("#372D56", "#FFFFFF")

  return (
    <chakra.svg
      aria-hidden
      viewBox="0 0 161 32"
      {...props}
    >
      <g clipPath="url(#clip0_358:350)">
        <path
          d="M70.0571 15.8503C69.6087 16.6596 68.9202 17.3114 67.9909 17.8078C67.0629 18.3043 65.9098 18.5521 64.532 18.5521H61.9855V24.6055H57.8784V7.74341H64.532C65.8773 7.74341 67.0149 7.97574 67.9435 8.4397C68.8715 8.90436 69.5692 9.54416 70.0331 10.3619C70.4971 11.1782 70.7301 12.1146 70.7301 13.1718C70.7301 14.1491 70.5056 15.0417 70.0571 15.8503ZM65.9733 14.7331C66.3582 14.3652 66.5496 13.8441 66.5496 13.1718C66.5496 12.4995 66.3582 11.9791 65.9733 11.6104C65.5892 11.2425 65.0051 11.0582 64.2206 11.0582H61.9855V15.2854H64.2199C65.0051 15.2861 65.5892 15.1018 65.9733 14.7331Z"
          fill={fillColor}
        />
        <path
          d="M76.9755 6.83032V24.6062H72.8677V6.83032H76.9755Z"
          fill={fillColor}
        />
        <path
          d="M93.1894 11.2024V24.6056H89.0823V22.7801C88.6649 23.3726 88.1007 23.8493 87.3889 24.2087C86.6763 24.5696 85.8875 24.7497 85.0225 24.7497C83.9978 24.7497 83.0932 24.5216 82.3079 24.0647C81.5227 23.6078 80.9147 22.9482 80.4825 22.0832C80.0503 21.2181 79.8335 20.2012 79.8335 19.0325V11.2024H83.9173V18.4809C83.9173 19.3785 84.1489 20.0748 84.6136 20.5705C85.0783 21.067 85.7025 21.3155 86.4871 21.3155C87.2879 21.3155 87.9199 21.067 88.3846 20.5705C88.8492 20.0748 89.0816 19.3785 89.0816 18.4809V11.2024H93.1894Z"
          fill={fillColor}
        />
        <path
          d="M117.222 12.5714C118.207 13.5806 118.7 14.9816 118.7 16.7753V24.6061H114.616V17.3275C114.616 16.4625 114.388 15.7944 113.931 15.3213C113.475 14.8496 112.847 14.613 112.046 14.613C111.244 14.613 110.617 14.8496 110.16 15.3213C109.704 15.7944 109.475 16.4625 109.475 17.3275V24.6061H105.392V17.3275C105.392 16.4625 105.164 15.7944 104.707 15.3213C104.25 14.8496 103.623 14.613 102.821 14.613C102.02 14.613 101.393 14.8496 100.936 15.3213C100.479 15.7944 100.251 16.4625 100.251 17.3275V24.6061H96.1436V11.2022H100.251V12.8836C100.667 12.3229 101.211 11.8787 101.885 11.5503C102.558 11.2219 103.318 11.0581 104.166 11.0581C105.176 11.0581 106.076 11.2742 106.869 11.7064C107.661 12.1386 108.281 12.755 108.73 13.5558C109.194 12.8193 109.828 12.2191 110.629 11.7544C111.429 11.2904 112.302 11.0581 113.247 11.0581C114.912 11.0581 116.238 11.5623 117.222 12.5714Z"
          fill={fillColor}
        />
        <path
          d="M127.347 11.5864C128.068 11.2023 128.892 11.0102 129.822 11.0102C130.926 11.0102 131.927 11.2905 132.824 11.8505C133.721 12.4112 134.429 13.212 134.95 14.2522C135.47 15.2938 135.73 16.5028 135.73 17.8799C135.73 19.2569 135.47 20.4701 134.95 21.5188C134.43 22.5675 133.721 23.3767 132.824 23.9452C131.927 24.5144 130.926 24.7983 129.822 24.7983C128.876 24.7983 128.052 24.609 127.347 24.2333C126.642 23.8576 126.09 23.357 125.689 22.732V24.6055H121.582V6.83032H125.689V13.0998C126.074 12.4755 126.627 11.9706 127.347 11.5864ZM130.698 15.4661C130.129 14.8821 129.428 14.5898 128.596 14.5898C127.779 14.5898 127.086 14.8864 126.518 15.4789C125.95 16.0713 125.666 16.8799 125.666 17.9053C125.666 18.9306 125.95 19.7392 126.518 20.3317C127.087 20.9242 127.779 21.2208 128.596 21.2208C129.413 21.2208 130.11 20.9199 130.686 20.3197C131.262 19.7194 131.551 18.9066 131.551 17.882C131.551 16.8573 131.267 16.0509 130.698 15.4661Z"
          fill={fillColor}
        />
        <path
          d="M150.455 18.8891H141.159C141.223 19.7224 141.492 20.3587 141.964 20.7986C142.437 21.2393 143.017 21.4596 143.706 21.4596C144.73 21.4596 145.444 21.0274 145.843 20.1623H150.215C149.991 21.0429 149.586 21.836 149.001 22.54C148.417 23.2455 147.684 23.7977 146.804 24.1981C145.923 24.5985 144.938 24.7991 143.849 24.7991C142.536 24.7991 141.367 24.5195 140.342 23.9587C139.317 23.398 138.516 22.5972 137.94 21.5563C137.364 20.5154 137.075 19.2987 137.075 17.9054C137.075 16.5121 137.359 15.2954 137.928 14.2538C138.496 13.2129 139.293 12.4128 140.317 11.8521C141.342 11.2921 142.519 11.0117 143.849 11.0117C145.146 11.0117 146.299 11.2843 147.308 11.8281C148.317 12.3725 149.105 13.1493 149.674 14.1584C150.243 15.1676 150.527 16.3448 150.527 17.6893C150.527 18.0728 150.503 18.4725 150.455 18.8891ZM146.323 16.6075C146.323 15.9027 146.083 15.3427 145.602 14.9261C145.122 14.5094 144.521 14.3011 143.801 14.3011C143.111 14.3011 142.531 14.5016 142.059 14.902C141.586 15.3024 141.294 15.8709 141.182 16.6075H146.323V16.6075Z"
          fill={fillColor}
        />
        <path
          d="M158.598 11.6951C159.319 11.2707 160.12 11.0588 161 11.0588V15.4061H159.871C158.846 15.4061 158.077 15.6271 157.565 16.067C157.051 16.5077 156.796 17.2802 156.796 18.3847V24.6068H152.688V11.2022H156.796V13.4358C157.277 12.7 157.877 12.1188 158.598 11.6951Z"
          fill={fillColor}
        />
      </g>
      <g clipPath="url(#clip1_358:351)">
        <path
          d="M23.167 8.26709C24.6584 8.26709 26.1164 8.70936 27.3565 9.53798C28.5966 10.3666 29.5632 11.5443 30.1339 12.9223C30.7047 14.3002 30.854 15.8164 30.5631 17.2793C30.2721 18.7421 29.5539 20.0857 28.4993 21.1404C27.4446 22.195 26.101 22.9132 24.6381 23.2042C23.1753 23.4952 21.6591 23.3458 20.2812 22.7751C18.9032 22.2043 17.7255 21.2377 16.8969 19.9976C16.0682 18.7575 15.626 17.2996 15.626 15.8081C15.6284 13.8088 16.4237 11.8922 17.8374 10.4785C19.2511 9.06484 21.1677 8.26955 23.167 8.26709V8.26709ZM23.167 19.8113C23.9587 19.8113 24.7327 19.5765 25.3911 19.1367C26.0494 18.6968 26.5625 18.0716 26.8655 17.3401C27.1685 16.6086 27.2478 15.8036 27.0933 15.0271C26.9388 14.2505 26.5576 13.5372 25.9977 12.9774C25.4378 12.4175 24.7245 12.0362 23.948 11.8818C23.1714 11.7273 22.3665 11.8066 21.635 12.1096C20.9035 12.4126 20.2783 12.9257 19.8384 13.584C19.3985 14.2423 19.1637 15.0163 19.1637 15.8081C19.1662 16.8691 19.5887 17.8859 20.339 18.6361C21.0892 19.3863 22.106 19.8089 23.167 19.8113Z"
          fill="url(#paint0_linear_358:352)" />
        <path
          d="M45.6311 11.3301V20.2862H42.0841V17.577H38.8629V16.4598C38.8535 15.1657 39.859 14.0765 41.1438 14.0393H42.0841V11.3301H45.6311Z"
          fill="url(#paint1_linear_358:361)" />
        <path
          d="M41.2744 14.0393H41.1441C39.8593 14.0765 38.8539 15.1658 38.8632 16.4599V17.5771C38.8445 17.8005 38.8166 18.0239 38.7794 18.2381C38.7794 18.2567 38.7701 18.266 38.7701 18.2753C38.1772 21.9889 36.2814 25.3701 33.4222 27.8131C30.563 30.256 26.9274 31.601 23.1667 31.607H22.8688C19.4477 31.5509 16.1389 30.3771 13.4472 28.2648C10.8028 26.1889 8.88065 23.3318 7.95439 20.1L7.87061 19.8114H11.5666L11.6225 19.9604C12.3256 21.9025 13.5058 23.6366 15.0546 25.0031C16.6034 26.3697 18.4709 27.3248 20.4855 27.7807H20.5413C21.404 27.967 22.2841 28.0606 23.1667 28.06C26.3264 28.0582 29.3636 26.8377 31.6459 24.6526C33.9281 22.4674 35.2795 19.4861 35.4185 16.3295C35.4105 16.2834 35.4074 16.2366 35.4092 16.1899V15.4265C35.4092 15.0586 35.5553 14.7057 35.8155 14.4456C36.0756 14.1855 36.4285 14.0393 36.7964 14.0393H41.2744Z"
          fill="url(#paint2_linear_358:372)" />
        <path
          d="M0.720703 20.2862V11.3301H4.26776V14.0393H7.48898V15.1564C7.49829 16.4412 6.49282 17.5305 5.20806 17.577H4.26776V20.2862H0.720703Z"
          fill="url(#paint3_linear_358:382)" />
        <path
          d="M5.07764 17.577H5.20797C6.49274 17.5305 7.4982 16.4412 7.48889 15.1565V14.0393C7.50751 13.8158 7.53544 13.5924 7.57268 13.3783C7.57486 13.3626 7.57797 13.3471 7.58199 13.3317C8.1784 9.61969 10.0753 6.24065 12.9337 3.7984C15.792 1.35615 19.4257 0.00982654 23.1853 0H23.4832C26.9051 0.0520345 30.2152 1.22626 32.9048 3.34224C35.5492 5.4181 37.4714 8.27527 38.3977 11.507L38.4815 11.7956H34.7854L34.7296 11.6466C34.0264 9.70455 32.8462 7.97045 31.2974 6.60389C29.7487 5.23733 27.8811 4.2822 25.8666 3.82636H25.8107C24.948 3.64003 24.0679 3.5464 23.1853 3.54706C20.0241 3.5489 16.9856 4.77071 14.7031 6.95782C12.4206 9.14492 11.0703 12.1286 10.9335 15.2868C10.9417 15.3298 10.9448 15.3735 10.9429 15.4171V16.1899C10.9429 16.5578 10.7967 16.9106 10.5366 17.1707C10.2764 17.4309 9.92358 17.577 9.55568 17.577H5.07764Z"
          fill="url(#paint4_linear_358:392)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_358:352" x1="30.4222" y1="10.143" x2="11.8061" y2="24.6729"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.21" stopColor={fillColor} />
          <stop offset="0.96" stopColor="#C1AAFD" />
        </linearGradient>
        <linearGradient id="paint1_linear_358:361" x1="41.9547" y1="8.51012" x2="42.6417" y2="22.6034"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.23" stopColor={fillColor} />
          <stop offset="0.83" stopColor="#C1AAFD" />
        </linearGradient>
        <linearGradient id="paint2_linear_358:372" x1="34.7426" y1="21.1195" x2="8.79042" y2="27.6503"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor="#C1AAFD" />
        </linearGradient>
        <linearGradient id="paint3_linear_358:382" x1="4.39717" y1="23.108" x2="3.7101" y2="9.01471"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.23" stopColor={fillColor} />
          <stop offset="0.81" stopColor="#C1AAFD" />
        </linearGradient>
        <linearGradient id="paint4_linear_358:392" x1="69248.8" y1="-30923.4" x2="77307.4" y2="-34777.3"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor="#C1AAFD" />
        </linearGradient>
        <clipPath id="clip0_358:350">
          <rect width="103.121" height="17.9679" fill={fillColor} transform="translate(57.8784 6.83008)" />
        </clipPath>
        <clipPath id="clip1_358:351">
          <rect width="44.9015" height="31.607" fill={fillColor} transform="translate(0.720703)" />
        </clipPath>
      </defs>
    </chakra.svg>
  )
}
