import React from 'react'
import {
  Box,
  Heading,
  useColorModeValue as mode
} from "@chakra-ui/react"
import {
  Illustration,
  MobileIllustration
} from "./InfoIcons"


export const Info = (props) => {
  return (
    <Box
      {...props}
      as="section"
      bg={mode('white', 'web')}
    >
      <Heading
        fontSize={["23px", "30px", null, "40px"]}
        fontWeight={["500"]}
        textAlign={["center"]}
        lineHeight={["36px"]}
        px={["35px", null, null, "0"]}
        color={mode('fontIllustration', 'cloud')}
        maxW={[null, null, "635px", "830px"]}
        m={["0", "0", "0 auto", "0 auto 70px auto"]}
      >
        The most advanced, open-source platform for data stream observability.
      </Heading>
      <MobileIllustration
        display={["block", "block", "none"]}
        paddingTop={["28px"]}
        margin={["0 auto"]}
        w={["292px"]}
        h={["566px"]}
      />
      <Illustration
        display={["none", "none", "block"]}
        paddingTop={["28px"]}
        margin={["0 auto", null, null, "45px auto 0 auto"]}
        alignSelf={["center"]}
        w={"613px"} h={"241px"}
        transform={[null, null, null, "scale(1.5)"]}
      />
    </Box>
  )
}