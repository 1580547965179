import * as React from 'react'
import Hero from '../components/Hero'
import Seo from '../components/seo'
import Stats from '../components/Stats'
import Info from '../components/Info'
import Benefits from "../components/Benefits"
import IntegrationsSection from '../components/Integrations'
import DownloadsSection from "../components/Download"
import Footer from "../components/Footer"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Hero paddingBottom={["90px"]}/>
    <Stats paddingTop={["70px"]}/>
    <Info paddingTop={["45px"]}/>
    <Benefits />
    <IntegrationsSection />
    <DownloadsSection />
    <Footer />
  </>
)

export default IndexPage
