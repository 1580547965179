import React from 'react'
import {
  Box,
  Heading,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { BenefitsData } from "./Data"

export const BenefitsContainer = (props) => {
  const bg = mode('#F9F7FF', '#443963')
  return (
    <Box
      w={["300px", "350px", "653px", "850px", "1100px", "1200px"]}
      maxW={["300px", "350px", "653px", "850px", "1100px", "1200px"]}
      m={["0px auto", null, null, "60px auto 0 auto", "60px auto 0 auto"]}
      bg={bg}
      borderRadius={"21px"}
      py={["53px", null, null, "70px"]}
    >
      <Heading
        fontSize={["20px", "24px"]}
        letterSpacing={["6px"]}
        textTransform={["uppercase"]}
        textAlign={["center"]}
        marginBottom={[null, null, null, "60px"]}
      >
        Benefits
      </Heading>
      <Box
        className="benefits-wrapper"
        display={["grid"]}
        placeContent={[null, null, null, "space-between"]}
        gridColumnGap={[null, null, "54px", null, "100px", "175px"]}
        gridTemplateColumns={["1fr", null, "1fr 1fr"]}
        placeSelf={["center"]}
        width={[null, null, "571px", "750px", "1100px", "1200px"]}
        maxW={[null, null, "571px", "750px", "1100px", "1200px"]}
        mx={["auto"]}
        px={["0px", null, null, null, "70px"]}
      >
      {BenefitsData.map((benefit, idx) => {
        return (
            <Box
              display={["flex"]}
              flexDirection={["column"]}
              justifyContent={["center", null, "flex-start"]}
              alignItems={["center", null, "flex-start"]}
              key={idx}
              my={["30px"]}
            >
              {benefit.icon}
              <Heading
                fontFamily={["InterVariable"]}
                fontSize={["18px", "22px", "16px", "22px"]}
                fontWeight={["600"]}
                textAlign={["center", null, "left"]}
                color={mode("fontIllustration", "cloud")}
                margin={["20px 0 10px 0", null, null, null, null, "30px 0 10px 0"]}
              >
                {benefit.label}
              </Heading>
              <Text
                fontSize={["14px", null, null, "16px"]}
                fontWeight={["400"]}
                textAlign={["center", null, "left"]}
                color={mode("fontIllustration", "cloud")}
                marginBottom={["0px"]}
                px={["32px", null, "0", "0"]}
              >
                {benefit.text}
              </Text>
            </Box>
        )
      })}
      </Box>
    </Box>
  )
}
export const BenefitsSection = (props) => {
  return (
    <Box
      as='section'
      {...props}
      bg={mode('cloud', '#372D56')}
      paddingTop={["50px"]}
    >
      <BenefitsContainer />
    </Box>
  )
}