import React from 'react'
import {
  chakra,
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import {
  LinuxIcon,
  AppleIcon,
  WindowsIcon,
  HelmIcon,
  DockerIcon,
} from "./Icons"

const platforms = {
  app: [
    {
      label: "Linux",
      icon: <LinuxIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Mac",
      icon: <AppleIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Windows",
      icon: <WindowsIcon h={["40px", "50px", "42px", null, "60px"]} />,
    }
  ],
  server: [
    {
      label: "Helm",
      icon: <HelmIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Docker",
      icon: <DockerIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Linux",
      icon: <LinuxIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Mac",
      icon: <AppleIcon h={["40px", "50px", "42px", null, "60px"]} />,
    },
    {
      label: "Windows",
      icon: <WindowsIcon h={["40px", "60px", "42px", null, "60px"]} />,
    }
  ]
}

export const PlatformContainer = (props) => {
  return (
    <chakra.a
      display={["flex"]}
      flexDirection={["column"]}
      justifyContent={["space-between"]}
      alignItems={["center"]}
      m={[`${props.m ? props.m : "0px"}`]}
      _hover={{
        textDecor:"underline",
        textUnderlineOffset: "5px",
        cursor: "pointer"
      }}
      {...props}
    >
      {props.icon}
      <Text
        fontSize={["14px", "16px", "14px"]}
        fontWeight={["700"]}
        paddingTop={["20px"]}
        textUnderlineOffset={["3px"]}
      >
        {props.label}
      </Text>
    </chakra.a>
  )
}

export const PlumberApp = (props) => {
  const bg = "linear-gradient(105.81deg, #C1AAFD -5.48%, #FFD260 227.04%)"
  return (
    <Box
      display={["flex"]}
      flexDirection={["column"]}
      justifyContent={["center", null, null, null, "flex-start"]}
      alignItems={["center"]}
      borderRadius={["21px"]}
      bg={bg}
      mx={["auto", null, "0"]}
      w={["100%"]}
      h={[null, null, null, null, "auto",]}
      maxW={["300px", "350px", "238px", null, "430px"]}
      maxH={[null, null, "184px", null, "212px"]}
      py={["27px"]}
      px={[null, null, "20px"]}
      color={["web"]}
    >
      <Heading
        fontSize={["25px", null, "20px", null, "30px"]}
        fontWeight={["500"]}
        my={["0"]}
      >
        Plumber App
      </Heading>
      <Grid
        gridTemplateColumns={["repeat(3, 1fr)"]}
        gridColumnGap={["10px", "20px", null, null, "50px"]}
        paddingTop={["20px"]}
      >
        {platforms.app.map((platform, index) => {
          return (
            <PlatformContainer
              key={index}
              icon={platform.icon}
              label={platform.label}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

export const PlumberServer = (props) => {
  const bg = "linear-gradient(129.74deg, #F7E7CC 24.97%, #FFD260 97.98%)"
  return (
    <Box
      display={["flex"]}
      flexDirection={["column"]}
      justifyContent={["center"]}
      alignItems={["center"]}
      borderRadius={["21px"]}
      bg={bg}
      marginTop={["27px", null, "0"]}
      marginBottom={["30px"]}
      mx={["auto", null, "0"]}
      maxW={["300px", "350px", "410px", null, "639px"]}
      w={["100%", null, "410px", null, "639px"]}
      h={["auto", null, "184px", null, "212px"]}
      py={["27px"]}
      color={["web"]}
    >
      <Heading
        fontSize={["25px", null, "20px", null, "30px"]}
        fontWeight={["500"]}
      >
        Plumber Server
      </Heading>
      <Box
        display={["flex"]}
        justifyItems={["center"]}
        justifyContent={["center"]}
        alignItems={["center"]}
        flexWrap={["wrap"]}
        m={["20px", "30px", 0]}
        gridTemplateColumns={["repeat(3, 1fr)"]}
        gridColumnGap={["30px", "30px", null, null, "50px"]}
        gridRowGap={["20px", "25px"]}
        paddingTop={["0", "0", "20px"]}
      >
        {platforms.server.map((platform, index) => {
          return (
            <PlatformContainer
              key={index}
              icon={platform.icon}
              label={platform.label}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export const DownloadsSection = (props) => {
  const bg = mode("cloud", "web")
  return (
    <Box
      as="section"
      className={["downloads"]}
      paddingTop={["40px"]}
      bg={bg}
      padding={["10px", "20px", null, "60px 0", "90px 0"]}
    >
      <Heading
        fontSize={["23px", "30px", null, "40px"]}
        fontWeight={["500"]}
        textAlign={["center"]}
        mx={["auto"]}
        maxW={["500px", null, null, "700px"]}
        paddingTop={["40px", null, null, "0"]}
        px={["20px", "40px", "10px", null]}
      >
        Installs on your favorite platform
      </Heading>
      <Text
        fontSize={["16px", null, null, null, "18px"]}
        fontWeight={["400"]}
        mx={["auto"]}
        my={["0px"]}
        textAlign={["center"]}
        paddingTop={["25px"]}
        paddingBottom={["25px", "37px"]}
        maxW={["320px", null, null, null, "350px"]}
      >
        Deploy our plumber binary (written in go) and manage it with our app.
      </Text>
      <Flex
        flexDirection={["column", "column", "row"]}
        gridColumnGap={[null, null, "20px"]}
        justifyContent={["center"]}
      >
        <PlumberApp />
        <PlumberServer />
      </Flex>
    </Box>
  )
}