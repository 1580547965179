import React from 'react'
import {
  LiveTailIcon,
  RealTimeSearchIcon,
  SchemaManagementIcon,
  FilterAndValidateIcon,
  MonitorAndAlertIcon,
  DataFlowIcon,
  GovernanceIcon,
  RelayIcon,
  ReplayIcon,
  ArchiveIcon
} from "./Icons"

export const BenefitsData = [
  {
    label: 'Live tail',
    text: 'All real-time streaming data in one place. See inside any event driven architecture. No experience required. ',
    icon: <LiveTailIcon
      w={["50px", "67px", "36px", null, "auto"]}
      h={["50px", "67px", "36px", null, "66px"]}
      marginLeft={["-10px", "-20px", "0"]}
      transform={[null, null, null, null, "scale(1.1)"]}
    />
  },
  {
    label: 'Real time search',
    text: 'Search hours or days of data in seconds with very low latency. Great for <think of something here>',
    icon: <RealTimeSearchIcon
      w={["50px", "73px", "auto", "auto", "auto"]}
      h={["41px", "59px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Schema management',
    text: 'Plumber “schema registry” super chargers your data streams by automagically infer ',
    icon: <SchemaManagementIcon
      w={["50px", "66px", "auto", null, "auto"]}
      h={["44px", "58px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Filter and validate',
    text: 'Create rules to sample, verify, and validate every piece of data flowing in your data streams. Great for filtering out sensitive data for your team members.',
    icon: <FilterAndValidateIcon
      w={["50px", "57px", "auto", null, "auto"]}
      h={["54px", "61px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Monitor and alert',
    text: 'Monitor any data stream and alert on defined conditions to your favorite ops tool',
    icon: <MonitorAndAlertIcon
      w={["50px", "71px", "auto"]}
      h={["43px", "60px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Data flow tests (coming soon)',
    text: 'Test the flow of your internal data flows along with our supported third parties',
    icon: <DataFlowIcon
      w={["50px", "66px", "auto"]}
      h={["49px", "65px", "36px", null, "66px"]}
    />
  },
  {
    label: `Governance (coming soon)`,
    text: 'Speed up your dev workflows on data streams with our granular permissions. No hit on stream performance.',
    icon: <GovernanceIcon
      w={["50px", "56px", "auto"]}
      h={["59px", "65px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Relay (coming soon)',
    text: 'Relay any filtered and validated data to internal tools or third parties. ',
    icon: <RelayIcon
      w={["50px", "61px", "auto"]}
      h={["49px", "59px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Replays (coming soon)',
    text: 'Rebuild missing state, recover from errors, and save developer resources by replaying events to a destination of your choice.',
    icon: <ReplayIcon
      w={["50px", "65px", "auto"]}
      h={["50px", "65px", "36px", null, "66px"]}
    />
  },
  {
    label: 'Archive (coming soon)',
    text: 'Create a real-time compressed backup of data streams transforming them into structured data lakes. Reducing complexity and saving your cash. ',
    icon: <ArchiveIcon
      w={["50px", "60px", "auto"]}
      h={["49px", "59px", "36px", null, "66px"]}
    />
  }
]