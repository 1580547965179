import React from 'react'
import {
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react"
import { Logo } from "../Header/Logo"
import {
  FacebookIcon,
  InstagramIcon,
  YCombinatorIcon,
  YoutubeIcon
} from "./Icons"



export const Backing = (props) => {
  return (
    <Flex
      justifyContent={["center", "center", "center", "center"]}
      {...props}
    >
      <Text
        fontFamily={`"InterVariable", sans-serif`}
        fontSize={["18px"]}
        fontWeight={["400"]}
      >
        backed by
      </Text>
      <YCombinatorIcon
        w={["29px"]}
        h={["29px"]}
        marginLeft={"10px"}
      />
    </Flex>
  )
}
export const FooterItems = (props) => {
  return (
    <Box
      paddingBottom={["0", "0", "20px"]}
    >
      <Flex
        align="center"
        justify={["space-between", "space-between", "center"]}
        direction={["column", "column", "row"]}
        gridColumnGap={[null, null, "23px"]}
        fontSize={["16px"]}
        paddingTop={["37px"]}
      >
        <Link
          href="#"
          marginBottom={["14px"]}
        >
          Docs
        </Link>
        <Link
          href="#"
          marginBottom={["14px"]}
        >
          Guides
        </Link>
        <Link
          href="#"
          marginBottom={["14px"]}
        >
          Features
        </Link>
        <Link
          href="#"
          marginBottom={["14px"]}
        >
          Download
        </Link>
        <Link
          href="#"
          marginBottom={["14px"]}
        >
          Blog
        </Link>
      </Flex>
    </Box>
  )
}
export const SocialsContainer = (props) => {
  return (
    <Flex
      {...props}
      maxW={["150px"]}
      mx={["auto", null, "0"]}
      gridColumnGap={[null, null, "40px"]}
      alignItems={["center"]}
      justifyContent={["space-between"]}
    >
      <FacebookIcon h={["20px"]} />
      <YoutubeIcon h={["20px"]} />
      <InstagramIcon h={["20px"]} />
    </Flex>
  )
}

export const FooterSocials = (props) => {
  const textColor = mode("web", "cloud")

  return (
    <Box
      display={[null, null, "none"]}
      py={["37px"]}
      {...props}
    >
      <Text
        fontWeight={["600"]}
        textAlign={["center"]}
        fontSize={["18px"]}
        marginBottom={["10px"]}
        color={textColor}
      >
        Follow us
      </Text>
      <SocialsContainer display={["flex", "flex", "none"]}/>
    </Box>
  )
}

export const CompanyInfo = (props) => {
  const lightMode = "linear-gradient(199.65deg, #FFFFFF -15.37%, #C1AAFD 270.4%), linear-gradient(95.79deg, #E45D33 -3.17%, #F2ACAA 145.44%)"
  const darkMode = "linear-gradient(89.47deg, #372D56 37.51%, #C1AAFD 186.21%)"
  const bg = mode(lightMode, darkMode)
  const textColor = mode("web", "cloud")

  return (
    <Flex
      justify={["center", null, "space-between"]}
      bg={bg}
      fontWeight={["600"]}
      py={["32px"]}
      px={[null, null, "40px"]}
    >
      <Text
        textAlign={["center"]}
        m={["0"]}
        color={textColor}
      >
        © {new Date().getFullYear()} {props.companyName}
      </Text>
      <SocialsContainer display={["none", "none", "flex"]}/>
    </Flex>
  )
}
export const Footer = (props) => {
  const lightMode = "linear-gradient(199.65deg, #FFFFFF -15.37%, #C1AAFD 270.4%), linear-gradient(95.79deg, #E45D33 -3.17%, #F2ACAA 145.44%)"
  const darkMode = "linear-gradient(92.28deg, #372D56 14.07%, #C1AAFD 166.04%)"
  const bg = mode(lightMode, darkMode)
  return (
    <Box
    as={"footer"}
    bg={bg}
    paddingTop={["53px"]}
    >
      <Logo
        w={["191px"]}
        mx={["auto"]}
        marginBottom={["20px"]}
        id0="9f5cbefq"
        id1="9f5cbef"
        id2="9f5cbe"
        id3="9f5cb"
        id4="9f5c"
        id5="9f5"
        id6="9f"
      />

      <Backing />
      <FooterItems />
      <FooterSocials />
      <CompanyInfo companyName={"Batch.sh Inc."} />
    </Box>
  )
}

