import React from 'react'
import {
  Box,
  Button,
  Heading,
  Image,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import HeroIllustration from "../../images/hero.webp"

export const Hero = () => {
  const lightBackground = "linear-gradient(206.97deg, #D7C7FE 26.78%, #FFFFFF 72.08%)"
  const darkBackground = "linear-gradient(209.47deg, #8C7ABD 2.66%, #382E57 67.49%)"

  return (
    <Box
      as="section"
      display={["flex"]}
      justifyContent={["center"]}
      bg={mode(lightBackground, darkBackground)}
    >
      <Box
        display="flex"
        flexDirection={['column', null, 'row']}
        justifyContent={["center", null, "center", "space-between"]}
        paddingBottom={["50px", null, "0"]}
        maxWidth={["320px", "428px", "768px", "960px", "1200px", "1440px", "1600px"]}
        gridColumnGap={[null, null, null, null, null, null, "133px"]}
      >
        <Box
          display="flex"
          flexDirection={['column']}
          justifyContent={["center", "center", "flex-start"]}
          alignItems={["center", "center", "flex-start"]}
          paddingLeft={["0px", "0px", "40px", null, "90px", "0"]}
          paddingTop={["0px", "0px", "20px", "60px", "125px"]}
          maxWidth={['100%']}
        >
          <Heading
            as="h1"
            fontSize={["40px", "55px", "40px", "45px", "60px", "70px"]}
            fontWeight={["500"]}
            textAlign={['center', 'center', 'left']}
            color={mode("web", "cloud")}
            py={['38px', '22px']}
            m={["0 auto", null, "0"]}
            maxWidth={["320px", "428px", null, "500px", "700px", "800px"]}
          >
            Stream Observability. Real Simple. Real Fast.
          </Heading>
          <Text
            align={["center", "center", "left"]}
            maxW={[null, null, "338px", "450px", "650px"]}
            px={["20px", "56px", "0"]}
            fontSize={["16px", null, "14px", "16px"]}
            lineHeight={"24px"}
            color={mode("#2B2343", "cloud")}
          >
            Plumber is a zero-ops tool for monitoring, alerting, and governing of data streams.
            <Box as={"br"} display={["none", "none", "block", "none"]} /> Alleviate pain of traditional data flow debugging for SREs and Data Ops.
          </Text>
          <Button
            fontSize={["14px", null, null, null, "16px"]}
            fontWeight={["700"]}
            color="#2B2343"
            width={["212px", null, "150px"]}
            h={[null, null, "38px", null, "47px"]}
            height={["47px"]}
            bg="#FFD260"
            alignSelf={["center", null, "flex-start"]}
            marginTop={["30px", null, "30px"]}
            _hover={{
              background: "linear-gradient(96.41deg, #C1AAFD -16.36%, #FFD260 101.19%)",
            }}
            borderRadius={["4px"]}
          >
            Book a Demo
          </Button>
        </Box>
        <Box
          display={["none", "none", "flex"]}
          paddingRight={["0px", "0px", "0px", "40px"]}
        >
          <Image
            w={[null, null, "290px", "350px", "450px", "530px"]}
            h={[null, null, "100%"]}
            objectFit={[null, null, "cover"]}
            src={HeroIllustration}
          />
        </Box>
      </Box>
    </Box>
  )
}